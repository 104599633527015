@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper-pagination-bullet-active {
	box-shadow: inset 0 0 0 3px white;
	border: 2px solid #9065FD;
}

.swiper-button-prev {
	scale: 0.5;
}

.swiper-button-next {
	scale: 0.5;
}

@layer utilities {}

/* width */
::-webkit-scrollbar {
	width: 15px;
	height: 15px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 50px #9065FD;
	border-radius: 15px;
	border: 5px solid transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #372A60;
	border-radius: 10px;
	background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	/* background: #372A60; */
}

* {
	margin: 0;
	padding: 0;
	font-family: 'poppins', sans-serif;
	scroll-behavior: smooth;
}

body.scrollbar-hide::-webkit-scrollbar {
	display: none;
}

body.scrollbar-hide {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.calendly-badge-widget {
	position: static !important;
}

.range-field {
	-webkit-appearance: none;
	width: 74vw;
	height: 20px;
	background: #d1d1d1;
	border-radius: 20px;
	/* box-shadow: inset 0 0 5px rgba(0, 0, 0, 1); */
	overflow: hidden;
}

.range-field::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: #372A60;
	cursor: pointer;
	box-shadow: -74.5vw 0 0 74vw #9065FD;
}


@media only screen and (min-width: 768px) {
	.pagesMain {
		padding: 0px 400px;
	}
	.locationMain {
		padding: 0px 300px;
	}
	.headOfficeHeading {
		font-size: 20px;
	}
}
@media only screen and (max-width: 720px) {
	.footerLogo {
		margin-bottom: 10px !important;
	}
	.headOfficeHeading {
		margin: 0px;
	}
	.headOfficeHeading {
		font-size: 14px;
		margin-bottom: 20px;
	}
}
